<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 1200"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.manageContacts.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Contacts ...
      </h3>
      <h3 v-else-if="company != null" class="font-weight-bold pa-1" small>
        <span>
          <i class="fad fa-user-tie mr-2"></i> <b>{{ company.name }}</b> Contacts</span
        >
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.manageContacts.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>
    <div v-if="slideouts.manageContacts.active">
      <v-container fluid>
        <div style="background: #eceff1; border-radius: 0.5rem" class="pa-5 inset-shadow">
          <div class="d-flex align-center" style="gap: 0.5rem">
            <h3 class="text--primary font-weight-black my-1 mr-3">Contacts</h3>
            <v-btn
              v-if="!readonly && $has(perms.CompanyContacts.Create)"
              color="info"
              ref="addContact"
              @click="createContact"
            >
              <span class="fs-12px"><i class="far fa-plus mr-2"></i>Create</span>
            </v-btn>
            <bulk-import-company-contacts
              v-if="!readonly && $has(perms.CompanyContacts.Update)"
              :id="options.companyId"
              @imported="getDataDebounced()"
            ></bulk-import-company-contacts>
          </div>
          <v-data-table
            dense
            :headers="headers"
            :items="contacts"
            :items-per-page="-1"
            @click:row="rowClicked"
            :loading="loadingStates.table"
            class="contacts-table elevation-2 mt-2"
            hide-default-footer
          >
            <template v-slot:progress>
              <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
            </template>
            <template v-slot:[`item.firstName`]="{ item }">
              <v-row style="margin: 5px 0px">
                <user-avatar :user="item" hide-roles disable-open-link></user-avatar>
              </v-row>
            </template>
            <template v-slot:[`item.phone`]="{ item }">
              <phone-number v-model="item.phone"></phone-number>
            </template>
            <template v-slot:[`item.addressLine1`]="{ item }">
              <address-summary :value="item"></address-summary>
            </template>

            <template v-slot:[`item.contactState`]="{ item }">
              {{ $getTextByValue(item.contactState, states) }}
            </template>

            <template v-slot:[`item.createdBy`]="{ item }">
              <user-avatar :user="item.createdBy" icon></user-avatar>
            </template>

            <template v-slot:[`item.updatedBy`]="{ item }">
              <user-avatar :user="item.updatedBy" icon></user-avatar>
            </template>

            <template v-slot:[`item.createDate`]="{ item }">
              <dater :date="item.createDate" date-only></dater>
            </template>

            <template v-slot:[`item.updateDate`]="{ item }">
              <dater :date="item.updateDate" date-only></dater>
            </template>

            <template v-slot:[`item.actions`]="{ item, index }">
              <v-menu dense offset-x right>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn icon elevation="0" v-bind="attrs" v-on="on" :disabled="readonly">
                    <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
                  </v-btn>
                </template>
                <v-list class="more-options-menu">
                  <v-list-item
                    @click="invite(item, index)"
                    v-if="
                      allowInvite &&
                      item.userInvitation == null &&
                      $has(perms.CompanyContacts.Invite)
                    "
                  >
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small>fal fa-paper-plane</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Invite to CoCo</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    disabled
                    v-else-if="
                      allowInvite &&
                      item.userInvitation != null &&
                      item.userInvitation.isAccepted &&
                      $has(perms.CompanyContacts.Invite)
                    "
                  >
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small>fal fa-check-double</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Invitation Sent & Accepted</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    disabled
                    v-else-if="
                      allowInvite &&
                      item.userInvitation != null &&
                      !item.userInvitation.isAccepted &&
                      $has(perms.CompanyContacts.Invite)
                    "
                  >
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small>fal fa-check-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Invitation Sent and Pending!</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="updateContact(item, index)"
                    v-if="$has(perms.CompanyContacts.Update)"
                  >
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small>fal fa-pen</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Update</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="cloneContact(item, index)"
                    v-if="$has(perms.CompanyContacts.Update)"
                  >
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small class="purple--text">fal fa-copy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="purple--text">Clone</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="del(item)" v-if="$has(perms.CompanyContacts.Delete)">
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small>fal fa-trash-alt red--text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="red--text">Delete</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:loading>
              <video width="250" muted loop autoplay style="padding: 0.5rem">
                <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
              </video>
              <p class="font-weight-bold">Searching the Cosmos...</p>
            </template>
            <template v-slot:no-data>
              <img width="300" src="/img/art/fogg-no-connection-2.png" />
              <p class="font-weight-bold">No Data Available!</p>
            </template>
          </v-data-table>
        </div>
      </v-container>
      <edit-contact
        :company-id="options.companyId"
        ref="editContact"
        nested
        @save="onContactSaved"
        @close="onContactClosed"
        @delete="onContactDeleted"
      ></edit-contact>
    </div>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="readonly" @click="closeUpdateSlideout()">
          <i class="fal fa-chevron-left mr-2"></i>Close
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../../plugins/permissions";
import Enums from "../../../../plugins/enums";
import companyContactsService from "../services/companyContacts-service.js";
import userInvitationApi from "../../../Accounts/services/user-invitation-service";
import contactsHeader from "../config/tables/contacts.header";
import EditContact from "./EditContact.vue";
import BulkImportCompanyContacts from "./BulkImportCompanyContacts.vue";
import PanelSizeControl from "../../../Shared/components/PanelSizeControl.vue";

export default {
  name: "company-contacts",
  data() {
    return {
      perms: perms,
      states: this.$options.filters.EnumToList(Enums.clientStates),
      headers: contactsHeader,
      isFetching: true,
      contactsTimerId: null,
      valid: false,
      company: null,
      isInitiallyLoaded: false,
      contacts: [],
      total: 0,
      options: {
        companyId: null,
        search: null,
        includeCompany: false,
        includeCompany: false,
        page: 1,
        itemsPerPage: 15,
        search: null,
        sortBy: ["createDate"],
        sortDesc: [true],
      },
      loadingStates: {
        table: false,
      },
      slideouts: {
        manageContacts: {
          active: false,
          fullWidth: false,
          isLoading: false,
        },
      },
      readonly: false,
      storedSearch: false,
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  computed: {
    allowInvite() {
      if (this.company != null && this.company.companyType == "Manufacture") return true;
      return false;
    },
    mainPanelSize() {
      return this.slideouts.manageContacts.fullWidth ? "100%" : this.nested ? "87%" : "1200px";
    },
  },
  methods: {
    open(id, editMode = false) {
      this.company = null;
      this.contacts = [];
      this.total = 0;
      this.isInitiallyLoaded = false;
      this.isFetching = true;
      // this.readonly = !editMode;
      this.readonly = false;
      this.options = {
        search: null,
        companyId: id,
        includeCompany: false,
        page: 1,
        itemsPerPage: 15,
        search: null,
        sortBy: ["createDate"],
        sortDesc: [true],
      };
      this.slideouts.manageContacts.active = true;
    },
    getData() {
      this.loadingStates.table = true;
      let optionsToSend = this.cloneDeep(this.options);
      if (this.storedSearch != optionsToSend.search) optionsToSend.page = 0;
      this.storedSearch = optionsToSend.search;
      if (!this.isInitiallyLoaded) {
        this.isFetching = true;
        optionsToSend.includeCompany = true;
      }
      this.$log("optionsToSend", optionsToSend);
      companyContactsService
        .query(this.$clean(optionsToSend, true))
        .then((resp) => {
          this.$log("resp.data.items", resp.data.items);
          this.contacts = resp.data.items;
          this.total = resp.data.total;
          if (!this.isInitiallyLoaded) {
            this.isInitiallyLoaded = true;
            this.isFetching = false;
            this.company = resp.data.company;
          }
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    getDataDebounced() {
      if (this.contactsTimerId == null) {
        this.contactsTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.contactsTimerId);

      // delay new call 400ms
      this.contactsTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      this.$emit("close");
    },
    closeUpdateSlideout() {
      this.slideouts.manageContacts.active = false;
    },
    createContact() {
      this.$refs.editContact.open(null);
    },
    updateContact(contact, index) {
      setTimeout(() => {
        this.$refs.editContact.open(contact.id, true);
      });
    },
    cloneContact(contact, index) {
      setTimeout(() => {
        this.$refs.editContact.clone(contact.id, true);
      });
    },
    viewContact(id) {
      setTimeout(() => {
        this.$refs.editContact.open(id);
      });
    },
    rowClicked(row) {
      this.viewContact(row.id);
    },
    onContactClosed() {},
    onContactSaved(contactData, isNewContact) {
      this.$log("onContactSaved", contactData, isNewContact);
      if (isNewContact) {
        this.addToArr(this.contacts, contactData);
      } else {
        this.updateArr(this.contacts, contactData);
      }
    },
    invite(contact, index) {
      this.$dialog
        .warning({
          text: `Invite this external contact to Concordia?`,
          title: `Invite to Concordia?`,
          color: "info",
          icon: "fas fa-paper-plane mr-4",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Invite",
              color: "info",
              handle: () => {
                return userInvitationApi
                  .add({ contactId: contact.id })
                  .then((resp) => {
                    contact.userInvitation = resp.data;
                    this.$dialog.notify.success("Invitation sent successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    del(contact) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete "<u>${contact.firstName} ${contact.lastName}</u>" from contacts?`,
          title: `Delete Contact?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return companyContactsService
                  .delete(contact.id)
                  .then((resp) => {
                    this.onContactDeleted(contact.id);
                    this.$dialog.notify.success("Contact deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onContactDeleted(id) {
      const index = this.contacts.indexOf(this.contacts.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.contacts.splice(index, 1);
      }
    },
  },
  watch: {
    options: {
      handler(val) {
        this.getDataDebounced();
      },
      deep: true,
    },
  },
  components: { EditContact, BulkImportCompanyContacts, PanelSizeControl },
};
</script>

<style lang="scss">
.contacts-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
</style>
